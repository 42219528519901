import { Card } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import BackspaceIcon from '@mui/icons-material/Backspace';
import FormikForm from './FormikForm';
import FormInputTextField from './FormInputTextField';
import { range } from '../functions/helpers';
import useApi from '../functions/useApi';

const NumberInputForm = ({ setNumber, aKey }) => {
  const { post } = useApi();
  const formik = useFormik({
    initialValues: {
      number: '',
    },
    validationSchema: Yup.object({
      number: Yup.number().min(1).max(1000).required('Number required'),
    }),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const res = await post({
          url: `ping/${aKey}`,
          body: values,
          noAuth: true,
        });

        console.log(res);
      } catch (error) {
        console.error(error);
      }

      setSubmitting(false);
      setNumber(values.number);
    },
  });

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      sx={{ minHeight: '10rem', mt: '2rem' }}
    >
      <Grid>
        <Card sx={{ width: '16rem' }}>
          <CardHeader title="Enter your number" />

          <CardContent>
            <FormikForm formik={formik}>
              <FormInputTextField
                key="number"
                id="number"
                name="number"
                type="number"
                fullWidth
              />
              <Grid sx={{ mt: '1rem' }} container spacing={2}>
                {[...range(1, 9, 1), 0].map((b) => (
                  <Grid sx={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (formik.values.number.length < 3) {
                          formik.setFieldValue(
                            'number',
                            `${formik.values.number}${b}`
                          );
                        }
                      }}
                    >
                      {b}
                    </Button>
                  </Grid>
                ))}

                <Grid sx={6}>
                  <Button
                    onClick={() => {
                      formik.setFieldValue(
                        'number',
                        formik.values.number.toString().slice(0, -1)
                      );
                    }}
                    variant="outlined"
                    color="primary"
                    sx={{ width: '9rem' }}
                  >
                    <BackspaceIcon />
                  </Button>
                </Grid>
              </Grid>

              <Button
                sx={{ mt: '1rem', mb: '0.1rem' }}
                type="submit"
                variant="contained"
                fullWidth
              >
                Enter
              </Button>
            </FormikForm>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NumberInputForm;
