import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useContext,
} from 'react';
// eslint-disable-next-line import/no-named-as-default
import UsersReducer from './UsersReducer';
import useApi from '../functions/useApi';
import { useAuthContext } from './AuthContext';
import { useCompanyContext } from './CompanyContext';

export const UsersContext = createContext();

export function useUsersContext() {
  return useContext(UsersContext);
}

const UsersContextProvider = (props) => {
  const [users, dispatchUsers] = useReducer(UsersReducer, []);
  const [refresh, setRefresh] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersHistoricalDataLoading, setUsersHistoricalDataLoading] =
    useState(false);
  const [historicalUserData, setHistoricalUserData] = useState([]);
  const { get, post } = useApi();
  const { isAdmin } = useAuthContext();
  const { details, oo } = useCompanyContext();
  const [firstIterationData, setFirstIterationData] = useState(false);
  function usersReload() {
    setRefresh(!refresh);
  }

  useEffect(async () => {
    if (isAdmin) {
      setUsersLoading(true);
      let cursor = 0;
      let partsOfUsers = [];

      while (cursor || cursor === 0) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const res = await get({ url: `users/${cursor}/0` });

          if (res.cursor > 0) {
            cursor = res.cursor;
          } else cursor = false;

          if (Array.isArray(res.message)) {
            partsOfUsers = [
              ...partsOfUsers,
              ...res.message.map((u) => ({
                ...u,
                ...(u.tags && { tags: JSON.parse(u.tags) }),
              })),
            ];
          }
        } catch (error) {
          console.error(error);
          // TODO add retry
          // dispatchUsers({
          //   type: 'LOAD_USERS',
          //   users: [],
          // });
        }
      }

      dispatchUsers({
        type: 'LOAD_USERS',
        users: partsOfUsers,
      });
      setUsersLoading(false);
    }
  }, [refresh]);

  const historicalRequest = async (v) => {
    if (v.length > 0) {
      let cursor = 0;
      let partsOfUsers = [];

      while (cursor || cursor === 0) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const res = await post({
            url: `assessment/history/${cursor}`,
            body: { iterations: v },
          });

          if (res.cursor > 0) {
            cursor = res.cursor;
          } else cursor = false;

          if (Array.isArray(res.message)) {
            partsOfUsers = [
              ...partsOfUsers,
              ...res.message.map((u) => ({
                ...u,
                ...(u.tags && { tags: JSON.parse(u.tags) }),
                ...(u.test && { test: JSON.parse(u.test) }),
              })),
            ];
          }
        } catch (error) {
          console.error(error);
        }
      }
      const questionData = {};

      return partsOfUsers;
    }
    return [];
  };

  useEffect(async () => {
    if (isAdmin && details.currentAssessmentIteration >= 0) {
      setUsersHistoricalDataLoading(true);

      // console.log(
      //   'currentAssessment',
      //   details.currentAssessmentIteration,
      //   details
      // );

      const iterationArray = Array.from(
        { length: details.currentAssessmentIteration },
        (_, i) => i + 1
      );
      // console.log(iterationArray);

      // console.log(details.currentAssessmentIteration);

      const value = 0;
      const iterationObject = iterationArray.reduce(
        // eslint-disable-next-line no-param-reassign, no-return-assign, no-sequences
        (acc, key) => (
          // eslint-disable-next-line no-sequences
          (acc[key] = { questionData: {}, combinedData: [], data: [] }), acc
        ),
        {}
      );

      const questionAnswers = [1, 2, 3, 4, 5];
      const combinedData = async () => {
        iterationArray.forEach((i) => {
          iterationObject[i].data.forEach((u) => {
            u.test.forEach((t) => {
              // question object exists so answer just needs to be incremented
              if (iterationObject[i].questionData[t.question_id]) {
                iterationObject[i].questionData[t.question_id][t.answer]++;
              } else {
                // question object does not exist so it needs to be created
                iterationObject[i].questionData[t.question_id] = {
                  ...{
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    question: t.question,
                    question_id: t.question_id,
                  },
                  ...{
                    [t.answer]: 1,
                  },
                };
              }
            });

            Object.keys(iterationObject[i].questionData).forEach((t, b) => {
              // multiply answer amount by question weight
              const sumOfQuestionResult = questionAnswers.reduce(
                (res, cur) =>
                  res + iterationObject[i].questionData[t][cur] * cur,
                0
              );

              // total sum of times question was answered
              const numberofResults = questionAnswers.reduce(
                (res, cur) =>
                  res + (iterationObject[i].questionData[t][cur] || 0),
                0
              );

              // console.log('sumOfQuestionResult', sumOfQuestionResult);
              // console.log('numberofResults', numberofResults);

              iterationObject[i].questionData[t].averageAnswer = Math.round(
                sumOfQuestionResult / numberofResults
              );

              iterationObject[i].questionData[t].sumOfQuestionResult =
                sumOfQuestionResult;

              iterationObject[i].questionData[t].numberofResults =
                numberofResults;
            });
          });
        });
      };

      // console.log(iterationObject);

      // console.time('first iteration');
      // console.time('entire iteration');
      //  Get data for latest iteration first
      if (iterationArray.length > 0) {
        const latestIteration = await historicalRequest([
          iterationArray[iterationArray.length - 1],
        ]);
        iterationObject[iterationArray[iterationArray.length - 1]].data =
          latestIteration;
        setFirstIterationData(latestIteration);
      }
      // manipulate data for latest iteration
      await combinedData();

      // console.timeEnd('first iteration');
      // console.log('first iteration', iterationObject);
      setHistoricalUserData(iterationObject);

      // console.log('latest iteration', iterationObject);

      // Get data for all other iterations
      const everyOtherIteration = await historicalRequest(
        iterationArray.slice(0, -1)
      );

      //  push data into iterationObject
      everyOtherIteration.forEach((i) => {
        iterationObject[i.assessment_iteration].data.push(i);
      });

      // manipulate data for all other iterations
      await combinedData();
      setHistoricalUserData(iterationObject);

      // console.timeEnd('entire iteration');
      // console.log('entire iteration', iterationObject);
      // console.log('all others iterations', iterationObject);

      // const iterations = {
      //   1: { combinedData: [], data: [] },
      //   2: { combinedData: [], data: [] },
      //   3: { combinedData: [], data: [] },
      //   4: { combinedData: [], data: [] },
      //   5: { combinedData: [], data: [] },
      // };
      // partsOfUsers.forEach((u) => {
      //   iterations[u.assessment_iteration].data.push(u);
      // });

      // // const iterationData

      // console.log('iterations', iterations);

      // setHistoricalUserData(partsOfUsers);
      // setFormAnswers(questionData);

      setUsersHistoricalDataLoading(false);

      // const v = iterationArray;
    }
  }, [refresh, details]);

  return (
    <UsersContext.Provider
      value={{
        users,
        dispatchUsers,
        usersReload,
        usersLoading,
        usersHistoricalDataLoading,
        historicalUserData,
        firstIterationData,
      }}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersContextProvider;
