import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Radio from './FormInputRadio';
import Select from './FormInputSelect';
import FormikForm from './FormikForm';
import FormButton from './FormButton';
import TextInput from './FormInputText';
import ColorPicker from './FormInputColorPicker';
import useApi from '../functions/useApi';
import { twoArraysEqual } from '../functions/helpers';
import HelpButton from './HelpButton';

const style = makeStyles(() => ({
  card: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
  },
  checked: { color: 'green', width: '100%', height: '5rem' },
}));

export default function Assessment(props) {
  const classes = style();
  const {
    windowSmall,
    form,
    aKey,
    helpButton,
    newQuestionOptions = false,
    number,
  } = props;
  // console.log(props);
  const { post } = useApi();

  // TODO: Find and fix browser errors ;-)

  const [successfullySubmitted, setSuccessfullySubmitted] = useState({
    submitted: false,
    error: false,
  });
  const [resetOption, setResetOption] = useState(false);
  const [submissionAttempts, setSubmissionAttempts] = useState(0);

  function setInitialValues() {
    let x = {};

    form.forEach((component) => {
      // formComponents.forEach((component) => {
      const i = {};
      if (component.type === 'colorPicker') {
        i[component.question] = [];
      } else i[component.question_id] = '';
      x = { ...x, ...i };
    });
    return x;
  }

  function ValidationSchema() {
    // const d = formComponents;
    const d = form;
    let x = {};

    d.forEach((component) => {
      const i = {};
      if (component.type === 'colorPicker') {
        i[component.question] = Yup.array()
          .min(16)
          .of(
            Yup.mixed().oneOf([
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
            ])
          )
          .required();
      } else {
        i[component.question_id] = Yup.string().min(1, 'Too Short!').required();
      }
      x = { ...x, ...i };
    });
    return Yup.object().shape(x);
  }

  const formik = useFormik({
    initialValues: setInitialValues(),
    validationSchema: ValidationSchema(),

    onSubmit: async (values) => {
      // TODO Data to submit function here
      const results = [];
      Object.keys(values).forEach((key) => {
        const result = {};
        if (key !== 'colorPicker') {
          result.questionId = key;
          result.answer = values[key];
          results.push(result);
        }
      });
      const body = {};
      body.colorPicker = values.colorPicker;
      body.results = results;

      if (number > 0) {
        body.number = number;
      }

      const compareAr = [...Array(16).keys()];

      const cp = values.colorPicker;

      if (
        (!twoArraysEqual(cp, compareAr) &&
          !twoArraysEqual(cp, compareAr.reverse())) ||
        submissionAttempts > 0
      ) {
        try {
          const res = await post({
            url: `form/submission${aKey ? `/${aKey}` : ''}`,
            body: body,
            noAuth: !!aKey,
          });
          console.log(res);
        } catch (error) {
          console.error(error);
        }
        // TODO: ERROR handling!!!!
        setSuccessfullySubmitted({ submitted: true, error: false });
      } else {
        try {
          const res = await post({
            url: 'form/submission/attempt',
            body: { attempts: submissionAttempts + 1 },
          });
          console.log(res);
        } catch (error) {
          console.error(error);
        }
        setSubmissionAttempts(submissionAttempts + 1);

        setResetOption(true);
      }
      formik.setSubmitting(false);
    },
  });

  // const Questions = formComponents.map((data) => {
  const Questions = form.map((data) => {
    // eslint-disable-next-line camelcase
    const { options, question, label, question_id } = data;
    // eslint-disable-next-line camelcase
    const questionId = question_id;
    switch (data.type) {
      case 'radio':
        return (
          <Radio
            options={options} // values to be passed
            key={questionId} // gridKey
            label={question}
            name={questionId.toString()} // formik name
          />
        );
      case 'dropdown':
        return (
          <Select
            options={options}
            key={questionId}
            label={question} // label
            name={question} // formik name
          />
        );
      case 'text':
        return (
          <TextInput
            key={question}
            id={questionId}
            name={question}
            label={label}
          />
        );
      case 'colorPicker':
        return (
          <ColorPicker
            key={question}
            show
            name={question}
            windowSmall={windowSmall}
            resetButton={resetOption}
          />
        );
      default:
        return null;
    }
  });

  return (
    <div>
      {!successfullySubmitted.submitted ? (
        <div>
          <Typography
            variant="h6"
            align="center"
            style={{ marginLeft: 30, marginTop: 30 }}
          >
            Please answer the following questions by selecting an answer from{' '}
            {newQuestionOptions ? '' : '1 - 5'}
          </Typography>
          <Typography
            variant="h6"
            align="center"
            style={{ marginLeft: 30, marginBottom: 30 }}
          >
            {newQuestionOptions
              ? 'Not true at all, Rarely true, Sometimes true, Often true, True nearly all the time'
              : 'With 1: Rarely, 2: Occasionally, 3: Often, 4: Usually, 5: Most of the Time'}
          </Typography>
          <Container key="form20" maxWidth="md">
            <FormikForm formik={formik}>
              {/* {!successfullySubmitted.submitted ? ( */}
              <Grid style={{ paddingBottom: '2rem' }} container spacing={3}>
                {Questions}
                <FormButton
                  fullWidth
                  text="submit"
                  loading={formik.isSubmitting}
                />
                {/* {noConnection && (
                <Paper variant="outlined" elevation={3}>
                  Connection can not be established please try again
                </Paper>
              )} */}
              </Grid>
              {/* ) : (
            <div>Success</div>
          )} */}
            </FormikForm>
          </Container>
        </div>
      ) : (
        <Card className={classes.card}>
          <Typography variant="h4" align="center" style={{ marginTop: 30 }}>
            Assessment complete
          </Typography>
          {number > 0 ? (
            <Button
              sx={{ mt: '2rem', mb: '2rem' }}
              variant="contained"
              onClick={() => window.location.reload()}
              fullWidth
            >
              Return to start
            </Button>
          ) : (
            <>
              <Typography
                variant="h5"
                align="center"
                style={{ marginTop: 10, marginLeft: 30 }}
              >
                Thank you for completing and please check your email to receive
                your personal and confidential results
              </Typography>
              <CheckCircleOutlineIcon className={classes.checked} />
              {helpButton && <HelpButton aKey={aKey} />}
            </>
          )}
        </Card>
      )}
    </div>
  );
}
