import * as XLSX from 'xlsx';
import format from 'date-fns/format';
import { answers } from '../constants/assessmentPointers';

const sortCat = [
  'hardiness',
  'coping',
  'adaptability',
  'meaningfulness',
  'optimism',
  'regulation_of_emotion',
  'self_efficacy',
];

const c25questions = [
  'I am able to adapt when changes occur',
  'I have one close and secure relationship',
  'Sometimes fate or faith helps me',
  'I can deal with whatever comes my way',
  'Past successes give me confidence',
  'I try to see the humorous side of things when I am faced with problems',
  'Having to cope with stress can make me stronger',
  'I tend to bounce back after illness, injury or other hardships',
  'I believe most things happen for a reason',
  'I make my best effort, no matter what',
  'I believe I can achieve my goals, even if there are obstacles',
  'Even when hopeless, I do not give up',
  'In times of stress, I know where to find help',
  'Under pressure, I stay focused and think clearly',
  'I prefer to take the lead in problem-solving',
  'I am not easily discouraged by failure',
  'I think of myself as a strong person when dealing with life’s challenges and difficulties',
  'I make unpopular or difficult decisions',
  'I am able to handle unpleasant or painful feelings like sadness, fear, and anger',
  'I have to act on a hunch',
  'I have a strong sense of purpose in life',
  'I feel like I am in control',
  'I like challenges',
  'I work to attain goals',
  'I take pride in my achievements',
];

// export const csvDownload = (data, fileName, keepExtras) => {
export const csvDownload = ({
  data,
  fileName = `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}-file`,
  keepExtras = false,
  resilience = false,
  safety = false,
  ignoreStringAnswer = false,
  researchQuestions = false,
}) => {
  if (data) {
    const parsedData = data.map((i) => ({
      ...i,
      ...{ tags: i.tags },
    }));

    // eslint-disable-next-line array-callback-return
    parsedData.map((p, index) => {
      parsedData[index].Name = parsedData[index].name;
      parsedData[index].Email = parsedData[index].email;
      parsedData[index]['Phone Number'] = parsedData[index].phone_number;
      parsedData[index]['Start Date'] = parsedData[index].start_date;

      delete parsedData[index].name;
      delete parsedData[index].email;
      delete parsedData[index].phone_number;
      delete parsedData[index].start_date;

      if (Array.isArray(p.tags)) {
        // eslint-disable-next-line no-return-assign
        p.tags.map((t) => (parsedData[index][t.tag_group] = t.tag));
      }

      if (Array.isArray(p.results)) {
        p.results.map(
          // eslint-disable-next-line no-return-assign
          (t) =>
            (parsedData[index][t.question] = ignoreStringAnswer
              ? t.answer
              : answers[t.answer])
        );
      }

      const temp = { ...parsedData[index] };

      if (!Array.isArray(p.results)) {
        delete parsedData[index].resilience;
        delete parsedData[index].safety;
      }

      delete parsedData[index].avg_resilience;
      delete parsedData[index].avg_safety;
      delete parsedData[index].last_test_date;
      delete parsedData[index].tests_completed;
      delete parsedData[index].last_seen;

      if (keepExtras) {
        const dateFormat = (date) =>
          date ? format(new Date(date), 'dd/MM/yy') : '';

        if (safety) {
          parsedData[index].safety = temp.safety;
          parsedData[index].avg_safety = temp.avg_safety;
        }
        if (resilience) {
          parsedData[index].resilience = temp.resilience;
          parsedData[index].avg_resilience = temp.avg_resilience;
        }
        parsedData[index].last_test_date = dateFormat(temp.last_test_date);
        parsedData[index].tests_completed = temp.tests_completed;
        parsedData[index].last_seen = dateFormat(temp.last_seen);
      }

      delete parsedData[index].is_admin;
      delete parsedData[index].user_id;
      delete parsedData[index].company_id;
      delete parsedData[index].tags;
    });

    // eslint-disable-next-line array-callback-return
    parsedData.map((p, index) => {
      parsedData[index].user_disabled = '';

      if (researchQuestions) {
        parsedData[index].c25 = c25questions.reduce(
          (res, cur) =>
            (parseInt(res, 10) || parseInt(p[res], 10)) + parseInt(p[cur], 10)
        );
      }
    });

    const wb = XLSX.utils.book_new();
    const getLongestObject = (arr) =>
      arr.reduce(
        (savedObj, obj) =>
          Object.keys(obj).length > Object.keys(savedObj).length
            ? obj
            : savedObj,
        ''
      );

    const ws = XLSX.utils.json_to_sheet(parsedData, {
      header: Object.keys(getLongestObject(parsedData)),
    });

    const sheetName = new Date().toISOString().split('T')[0];

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName || 'users'}.csv`);
  } else console.error('No data');
};

export default csvDownload;

export const allTestsCsvDownload = ({
  data,
  fileName = `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}-file`,
  cernova = false,
}) => {
  const wb = XLSX.utils.book_new();
  const getLongestObject = (arr) =>
    arr.reduce(
      (savedObj, obj) =>
        Object.keys(obj).length > Object.keys(savedObj).length ? obj : savedObj,
      ''
    );

  const parsedData = cernova
    ? data.map((i) => ({
        ...i,
        ...{
          // sum of all sortCategories
          c25: sortCat.reduce(
            (res, cur) =>
              (parseInt(res, 10) || parseInt(i[res], 10)) + parseInt(i[cur], 10)
          ),
        },
      }))
    : data.map((i) => {
        const res = parseInt(i.resilience, 10);
        return {
          user_id: i.user_id,
          test_date: format(new Date(i.results_date), 'MM/dd/yyyy HH:mm:ss'),
          // eslint-disable-next-line no-nested-ternary
          score: res <= 19 ? 'depleted' : res <= 79 ? 'normal' : 'excessive',
        };
      });

  const ws = XLSX.utils.json_to_sheet(parsedData, {
    header: Object.keys(getLongestObject(parsedData)),
  });

  const sheetName = new Date().toISOString().split('T')[0];

  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${fileName || 'users'}.csv`);
};
